import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent  {

  message: string | undefined;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  close() {
    this.dialogRef.close();
  }

}
