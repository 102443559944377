<br />
<br />
<div class="title">Send Us A Message</div>

<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form">

    <div class="label">
      <mat-label class="text">TELL US YOUR NAME *</mat-label>
    </div>
    <mat-form-field appearance="outline" class="field">
      <input matInput placeholder="Name" formControlName="name" [maxlength]="50" />
      <mat-error *ngIf="hasError('name', 'required')">{{Validation.Required}}</mat-error>
      <mat-error *ngFor="let msg of getControl('name').errors?.serverError">{{msg}}</mat-error>
    </mat-form-field>

    <div class="label">
      <mat-label class="text">ENTER YOUR EMAIL *</mat-label>
    </div>
    <mat-form-field appearance="outline" class="field">
      <input matInput placeholder="youremail@gmail.com" formControlName="email" [maxLength]="45" />
      <mat-error *ngIf="hasError('email', 'required')">{{Validation.Required}}</mat-error>
      <mat-error *ngIf="hasError('email', 'email')">This is not a valid email address</mat-error>
      <mat-error *ngFor="let msg of getControl('email').errors?.serverError">{{msg}}</mat-error>
    </mat-form-field>

    <div class="label">
      <mat-label class="text">ENTER PHONE NUMBER</mat-label>
    </div>
    <mat-form-field appearance="outline" class="field">
      <input matInput placeholder="### ### ####" formControlName="phone" type="number" min="0" />
      <mat-error *ngIf="hasError('phone', 'required')">{{Validation.Required}}</mat-error>
      <mat-error *ngFor="let msg of getControl('phone').errors?.serverError">{{msg}}</mat-error>
    </mat-form-field>

    <div class="label">
      <mat-label class="text">MESSAGE *</mat-label>
    </div>
    <mat-form-field appearance="outline" class="field">
      <textarea matInput placeholder="Write us a message"
                formControlName="message"
                [maxLength]="500"
                style="max-height:300px;"></textarea>
      <mat-error *ngIf="hasError('message', 'required')">{{Validation.Required}}</mat-error>
      <mat-error *ngFor="let msg of getControl('message').errors?.serverError">{{msg}}</mat-error>
    </mat-form-field>

    <div class="button-div">
      <button mat-flat-button class="btn" type="submit">SEND MESSAGE</button>
    </div>
  </form>
</div>
