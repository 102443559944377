<div class="container animatable reveal">
  <div class="description">
    <div class="desc-title">Meet our cutting-edge product</div>
    <div>
      Unlock the power of automated trading with our <span class="highlight">cutting-edge bot</span>.
      Maximize your profits and minimize your efforts with strategies designed for today's markets.
      Click below to explore how our bot can work for you!
    </div>
    <div>
      <button mat-flat-button class="btn" type="button" (click)="navigateToBot()">
        Learn more
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  </div>
  <div class="bee-bot"></div>
</div>
