import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBaseComponent} from "../../../../core/helper-components/form.base.component";
import {FormBuilder, FormGroupDirective, Validators} from "@angular/forms";
import {CreateFeedbackCommand, FeedbackClient} from "../../../../web-api-client";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-create-feedback',
  templateUrl: './create-feedback.component.html',
  styleUrls: ['./create-feedback.component.scss']
})
export class CreateFeedbackComponent  extends FormBaseComponent implements OnInit {

  @ViewChild(FormGroupDirective) formGroupDirective!: FormGroupDirective;

  constructor(
    private client: FeedbackClient,
    private  notifier: NotifierService,
    fb: FormBuilder) {
    super(fb);
  }

  onSubmit(){
    this.validateForm()
    if(this.formInvalid()){
      return;
    }
      let cmd = <CreateFeedbackCommand>this.form.value;
    this.disabled = true;
    this._subs.add(
      this.client.create(cmd)
        .subscribe(_ => {
          this.notifier.notify('succes', 'Thank you for feedback')
          this.formGroupDirective.resetForm();
          this.disabled = false;
        }, (error) => {
          this.disabled = false;
          this.handleError(error.errors);
        })
    )
  }
  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private initForm(){
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['' , [Validators.required]],
      message: ['', Validators.required]
    });
  }
}
