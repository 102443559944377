<div class="contact-us">
  <div class="img-bg">
    <div class="bg-dark">
      <div class="main-container">
        <div class="container">
          <mat-icon>pin_drop</mat-icon>
          <div class="text">
            <span class="info">Address</span>
            <p class="sub-info">Yerevan, Armenia</p>
          </div>
        </div>
        <div class="container">
          <mat-icon>phone</mat-icon>
          <div class="text">
            <span class="info">Lets Talk</span>
            <p class="sub-info" style="color: #0DC082">+374 97 178384</p>
          </div>
        </div>
        <div class="container">
          <mat-icon>email</mat-icon>
          <div class="text">
            <span class="info">General Suppport</span>
            <p class="sub-info green" style="color: #0DC082">capitalsoftam@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="feed-back">
    <app-create-feedback></app-create-feedback>
  </div>

</div>
