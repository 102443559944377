import {Component, OnInit} from '@angular/core';
import {IdentityService} from "../../../providers/services/identity.service";
import {MatDialog} from "@angular/material/dialog";
import {ComponentBindingService} from "../../../providers/services/component-binding.service";
import {Router} from "@angular/router";
import {BaseHelperComponent} from "../../../core/helper-components/base.helper-component";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {IdentityClient, SignOutCommand} from "../../../web-api-client";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseHelperComponent implements OnInit {
  isSignedIn = false;

  constructor(
    public identityService: IdentityService,
    private identityClient:IdentityClient,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isSignedIn = this.identityService.isSignedIn
  }


  signOut() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {autoFocus: false});
    this._subs.add(confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this._subs.add(
          this.identityClient.signOut(new SignOutCommand()).subscribe(
            {
              next: (next) => {
                this.identityService.removeAccessToken();
                location.reload();
              }, error: (error) => {
                this.identityService.removeAccessToken();
                location.reload();
              }
            }
          ));
      }
    }));
  }


}
