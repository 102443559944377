<div class="bg-img">
  <div class="dark-bg">
    <div id="sectionElement" #servicesElement class="center animatable reveal">
      <div class="texts">
        <span style="font-weight:100;">Work </span>
        <span class="text-2"><b> Plan</b></span>
      </div>
    </div>

    <div class="title-container animatable reveal">
      <div class="inner-container">
        <mat-icon class="mat-icon-size">forum</mat-icon>
        <br /><br />
        <span class="title">Discussion</span>
        <br /><br />
        <span class="text">
          What is the basis of the application?
          What are the main tasks of the site?
          Who is your target audience?
          What results do you want to get with this? What is the main theme?
          Answers to these and other questions determine what our product will be.
        </span>
      </div>


      <div class="inner-container">
        <mat-icon class="mat-icon-size">edit</mat-icon>
        <br /><br />
        <span class="title">Concept Creation</span>
        <br /><br />
        <span class="text">
          Where is it more convenient to place the design elements of the site?
          How to make navigation as easy and convenient as possible?
          How to achieve maximum usability?
          Solving these issues leads to the appearance of several design options.
        </span>
      </div>

      <div class="inner-container">
        <mat-icon class="mat-icon-size">code</mat-icon>
        <br /><br />
        <span class="title">Development</span>
        <br /><br />
        <span class="text">
          Wow! This is the most desperate stage on which they code, pedal, program,
          sawing the code, swear, it happens
          ... As a result, we get a beautiful,
          cross-browser, adaptive, fast user-friendly site.
        </span>
      </div>

      <div class="inner-container">
        <mat-icon class="mat-icon-size">favorite</mat-icon>
        <br /><br />
        <span class="title">Satisfied Customer</span>
        <br /><br />
        <span class="text">
          It remains only to enjoy the result.
          And the client must fill the resource with thematic content for further prosperity
          and promotion of their business in the network.
        </span>
      </div>

    </div>
  </div>

</div>
