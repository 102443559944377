<div id="sectionElement" #servicesElement class="center animatable reveal">
  <div class="line"></div>
  <div class="texts">
    <span class="text-1">
      Our
    </span>
    <span class="text-2"><b>Technologies</b></span>
  </div>
  <br/>
  <br/>
  <div class="line1"></div>
  <div class="line"></div>
  <div class="container">
    <owl-carousel-o [options]="carouselOptions" #owlCarousel>
      <ng-container *ngFor="let technology of technologies">
        <ng-template carouselSlide>
          <div class="technology" drag-scroll-item>
            <app-image-display [id]="technology.fileId" [customStyle]="'img-size'"></app-image-display>
            <p>{{ technology.name }}</p>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

<!--  <div class="slider-container">-->
<!--    <div class="carousel-container">-->
<!--      <button (click)="prev()" class="prev">&#10094;</button>-->
<!--      <div class="carousel" [style.transform]="'translateX(' + (-currentIndex * itemWidth) + 'px)'">-->
<!--        <div *ngFor="let technology of technologies" class="technology" drag-scroll-item>-->
<!--          <app-image-display [id]="technology.fileId" [customStyle]="'img-size'"></app-image-display>-->
<!--          <p style="margin-left:15px;">{{ technology.name }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <button (click)="next()" class="next">&#10095;</button>-->
<!--    </div>-->
<!--  </div>-->
</div>


