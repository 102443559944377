import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from "@angular/core";

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {
  itemsPerPageLabel = 'Տողերի քանակը էջում';
  nextPageLabel = 'Հաջորդ էջ';
  previousPageLabel = 'Նախորդ էջ';
  firstPageLabel = 'Առաջին Էջ';
  lastPageLabel = 'Վերջին Էջ';

  getRangeLabel = function (page:number, pageSize:number, length:number) {
    if (length === 0 || pageSize === 0) {
      return 'Չկա տվյալ';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' -ը ' + length + ' -ից ';
  };
}
