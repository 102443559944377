<div id="sectionElement" class="center animatable reveal">
  <div class="line"></div>
  <div class="texts">
    <span class="text-1">
      Our
    </span>
    <span class="text-2"><b>Works</b></span>
  </div>
  <p class="description" style="text-align: center;">
    We opened in September 2017 and are very proud of the results achieved. We completed a number of projects, both
    desktop applications and web projects, worked on various projects for corporations, government agencies, non-profit
    organizations and individuals.
    For all of the above, we need a team of good specialists, and, of course, we have one. You can rely on them.
  </p>
  <div class="line1"></div>
  <div class="line"></div>
</div>

<div class="list-wrapper">
  <ul class="list" #list>
    <li *ngFor="let p of projects" class="item" drag-scroll-item>
      <app-image-display [id]="p.coverPhotoId" [customStyle]="'project-img'"></app-image-display>
      <div class="work-info">
        <p>{{ p.name }}</p>
        <button mat-button class="view-btn button-hover" (click)="openDialog(p.id)">
          View details
          <mat-icon>double_arrow</mat-icon>
        </button>
      </div>
    </li>
  </ul>
  <!-- We're calling `handleClick` with a direction argument when the buttons are clicked -->
  <button (click)="handleClick('previous')" class="button button--previous" type="button">➜</button>
  <button (click)="handleClick('next')" class="button button--next" type="button">➜</button>
</div>
