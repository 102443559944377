import {Injectable, Injector} from "@angular/core";
import {BehaviorSubject, Subject, throwError} from "rxjs";


@Injectable({
  providedIn: "root",
})
export class ComponentBindingService {
  loading: boolean = false;
  isSidebarOpened = false;
  documentSidebarInfo$ = new Subject<any>();
  open403Popup$ = new Subject<boolean>();


  constructor() {
  }


}
