<div mat-dialog-title>
  <h1>{{ project?.name }}</h1>
  <button mat-icon-button><mat-icon mat-dialog-close>clear</mat-icon></button>
</div>
<mat-dialog-content>
  <div class="techs">
    <div class="secondary-title">
      Technologies and languages
    </div>
    <mat-chip-list class="example-chip">
    <mat-chip
      class="example-box"
      *ngFor="let item of project?.technologies">
      {{ item?.name }}
    </mat-chip>
  </mat-chip-list>
  </div>

  <div class="cover">
    The challenge and solution
  </div>
  <div class="description-text" [innerHTML]="description(project?.description)"></div>

  <div class="slide" *ngIf="imageObject">
    <div #container class="carousel firstInit" style="width: 100%">
      <ng-image-slider
        [images]="imageObject"
        [infinite]="false"
        [manageImageRatio]=true
        [autoSlide]="1"
        [imageSize]="{width: container.style.width, space:0, height:500}"></ng-image-slider>
    </div>
  </div>
</mat-dialog-content>
