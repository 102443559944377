<div>
  <div id="sectionElement" class="center animatable reveal">
    <div class="line"></div>
    <div class="texts">
      <span class="text-1">
        Our
      </span>
      <span class="text-2"><b>Services</b></span>
    </div>
    <p class="description">
      We are engaged in outsourcing, helping make ideas real, and our customers can see and enjoy use of product.
      <span class="center">
        Our services span the full range of IT needs, from plan and design to development and maintenance.
      </span>
    </p>
    <div class="line1"></div>
    <div class="line"></div>
  </div>


  <div class="services">
    <div class="container">
      <div class="inner-container">
        <mat-icon>public</mat-icon>
        <div class="title">WEB</div>
        <span class="text">
          We help your business grow, and it's not just words.
          If you need a website for your company so that people can learn
          more about your organization and your services,
          we can organize it all.
          Our company will provide good design,
          creative ideas and very clear interface.
        </span>
      </div>
    </div>
    <div class="container">
      <div class="inner-container">
        <mat-icon>phone_iphone</mat-icon>
        <div class="title">Mobile</div>
        <span class="text">
          Cross-platform development of mobile applications for iOS and Android.
          We are successfully able to create geolocation and media services,
          we always take into account the features of the platform,
          the scope of the product and its audience.
        </span>
      </div>
    </div>
    <div class="container">
      <div class="inner-container">
        <mat-icon>groups</mat-icon>
        <div class="title">Training</div>
        <span class="text">
          We really want all those who are interested in the it industry
          to be able to become part of this sphere. To do this,
          it is very important to organize regular trainings so that
          everyone can determine their profession and see everything from the inside.
        </span>
      </div>
    </div>
  </div>
</div>
