import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialModule } from '../../../material.module';
import {HomeComponent} from "./home.component";
import { CreateFeedbackComponent } from './create-feedback/create-feedback.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import {ImageDisplayComponent} from "../../../components/image-display/image-display.component";
import {FileService} from "../../../providers/services/fileService";
import { ServicesComponent } from './services/services.component';
import { WorksComponent } from './works/works.component';
import { AboutComponent } from './about/about.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WorkDetailsComponent } from './work-details/work-details.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FooterComponent } from './footer/footer.component';
import { BasebeeComponent } from './basebee/basebee.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";
import {CarouselModule} from "ngx-owl-carousel-o";

const routes: Routes = [
  {
    path: 'project/:id',
    component: WorkDetailsComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
]
@NgModule({
  declarations: [
    HomeComponent,
    CreateFeedbackComponent,
    TechnologiesComponent,
    ImageDisplayComponent,
    ServicesComponent,
    WorksComponent,
    AboutComponent,
    ContactUsComponent,
    WorkDetailsComponent,
    FooterComponent,
    BasebeeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgImageSliderModule,
    DragScrollModule,
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  exports: [
    HomeComponent,
  ],
  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    FileService
  ]
})
export class HomeModule{}
