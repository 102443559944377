<div class="wrapper" #el>
  <mat-card>
    <div id="header">
      <div class="container">
        <div id="info">
          <span>Email: info@capitalsoft.am</span>
        </div>

        <div id="social-icons">
          <ul>
            <mat-divider vertical></mat-divider>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100010542516857"
                 target="_blank" class="icon-facebook">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <mat-divider vertical></mat-divider>
            <li>
              <a href="https://www.comcapitalsoft@gmail.com"
                 target="_blank" class="icon-google_plus">
                <i class="fab fa-google-plus-g"></i>
              </a>
            </li>
            <mat-divider vertical></mat-divider>
            <li>
              <a href="https://www.skype.com"
                 target="_blank" class="icon-tumblr">
                <i class="fab fa-skype"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </mat-card>
  <div class="nav">
      <div class="navbar">
        <div class="brand">
          <img src="assets/images/logo.png"/>
        </div>
        <div class="links nav-items">
          <a href="#">Home</a>
          <a href="#services">Services</a>
          <a href="#works">Works</a>
          <a href="#about">About</a>
          <a href="#contactus">Contact us</a>
        </div>
        <div class="menu" (click)="onMenu()">
          <input type="checkbox" hidden id="hamburger"/>
          <label for="hamburger" [hidden]="isOpen"><mat-icon>menu</mat-icon></label>
          <label for="hamburger" [hidden]="!isOpen"><mat-icon>close</mat-icon></label>
        </div>
        <div class="dropdown" id="dropdown" [class.show]="isOpen">
          <a href="#">Home</a>
          <a href="#services">Services</a>
          <a href="#works">Works</a>
          <a href="#about">About</a>
          <a href="#contactus">Contact us</a>
        </div>
      </div>
    </div>
  <div class="bg-image">
    <div class="title center">
      <div class="title-text">Capital <span class="title-item">Soft</span></div>
      <div class="sub-text">SOFTWARE TAILORED TO YOUR NEEDS</div>
    </div>
  </div>
  <app-services id="services"></app-services>
  <app-works id="works"></app-works>
  <app-basebee></app-basebee>
  <app-technologies></app-technologies>
  <app-about id="about"></app-about>
  <app-contact-us id="contactus"></app-contact-us>
</div>
<app-footer></app-footer>
