import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class FileService {

  constructor(
    public httpClient: HttpClient) {

  }

  public uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post('api/FileBase', formData);
  }

  public downloadFile(id : any): any{
    return this.httpClient.get('api/FileBase/download/'+id,  { responseType: 'blob' });
  }
}
