import {Injectable} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlSegment,
} from "@angular/router";
import {IdentityService} from "../services/identity.service";

@Injectable({providedIn: "root"})
export class UnauthorizeGuard implements CanActivate {
  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.identityService.isSignedIn) {
      return true
    }

    this.identityService.navigateToHomePage();
    return false;
  }
}
