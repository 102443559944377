import {Injectable} from '@angular/core';
import {IdentityService} from "./identity.service";
import {IdentityClient} from "../../web-api-client";

@Injectable()
export class AppInitializeService {

  constructor(private identityService: IdentityService, private identityClient: IdentityClient) {
  }

  Init() {
    return new Promise<void>((resolve, reject) => {

      if (this.identityService.accessToken) {
        this.identityClient.sendHeartbeat().subscribe(
          {
            next: (next) => {
              if (this.identityService.isSignedIn) {
                this.identityService.startRefreshTokenTimer(false);
              }
            }
          }
        );
      }

      resolve();

    });
  }


}
