import {Injectable} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import {IdentityService} from "../services/identity.service";

@Injectable({providedIn: "root"})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.identityService.isSignedIn
    ) {
      let rolesAreValid = false;
      let scopesAreValid = false;

      //check roles
      if (route.data.roles && route.data.roles.length > 0) {
        rolesAreValid = this.identityService.isInRole(route.data.roles);
      } else {
        rolesAreValid = true;
      }

      //check scope
      if (route.data.scopes && route.data.scopes.length > 0) {
        scopesAreValid = this.identityService.isInScope(route.data.scopes);
      } else {
        scopesAreValid = true;
      }

      return rolesAreValid && scopesAreValid;
    }

    this.identityService.navigateToLoginPage();
    return false;
  }
}
