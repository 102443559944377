<header class="header">
  <div class="header-wrapper">
    <h2 class="header__title">
      <a routerLink="/admin">
        <span>Admin panel</span>
      </a>
    </h2>
  </div>

  <div>
    <ul *ngIf="isSignedIn; else unauthorizedMenu" class="header__navigation">
      <li *ngIf="isDevelopment()">
        <a href="/api" target="_blank">API</a>
      </li>
      <li>
        <a [routerLink]="'technology'">Technologies</a>
      </li>
      <li>
        <a [routerLink]="'projects'">Projects</a>
      </li>
      <li>
        <a  [routerLink]="'feedbacks'" >FeedBack</a>
      </li>
      <li>
        <a [routerLink]="'gallery'" >Gallery</a>
      </li>
      <li>
        <button class="sign-out" mat-button (click)="signOut()">
         Log out
        </button>
      </li>
    </ul>

    <ng-template #unauthorizedMenu>
      <ul class="header__navigation">
        <li *ngIf="isDevelopment()">
          <a href="/api" target="_blank">API</a>
        </li>
      </ul>
    </ng-template>

  </div>
</header>
