<div class="heading">
  <div class="buttons">
    <button *ngIf="showBackButton" mat-icon-button (click)="back()" class="back">
      <mat-icon class="back__icon">west</mat-icon>
    </button>
    <h1>{{title}}</h1>
  </div>
  <div class="buttons" *ngIf="showButtons">
    <a *ngIf="showAddButton" class="button-add" [routerLink]="route">Ավելացնել</a>
    <a *ngIf="showDisabledButton" class="button-add button-disabledUsers" routerLink="disabled">Ապաակտիվացվածներ</a>
  </div>
</div>
