import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BaseHelperComponent} from "../../../../core/helper-components/base.helper-component";
import {ProjectBriefResponse, ProjectClient} from '../../../../web-api-client';
import {WorkDetailsComponent} from '../work-details/work-details.component';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent extends BaseHelperComponent implements OnInit {

  projects!: ProjectBriefResponse[];
  sh = true;

  constructor(
    private dialog: MatDialog,
    private projectClient: ProjectClient,
  ) {
    super()
  }

  ngOnInit(): void {
    this.getProjects()
  }

  openDialog(id?: number) {
    this.dialog.open(WorkDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'fullscreen',
      data: {id: id}
    });
  }

  private getProjects() {
    this._subs.add(
      this.projectClient.getProjectBriefs()
        .subscribe(_ => {
          this.projects = _;
        })
    )
  }

  @ViewChild('list') list!: ElementRef;
  // We want to know the width of one of the items. We'll use this to decide how many pixels we want our carousel to scroll.
  item = document.querySelector(".item");
  itemWidth: number = 340;

  handleClick(direction:any) {
    // Based on the direction we call `scrollBy` with the item width we got earlier
    if(direction === "previous") {
      this.list.nativeElement.scrollBy({ left: - this.itemWidth, behavior: "smooth" });
    } else {
      this.list.nativeElement.scrollBy({ left: this.itemWidth, behavior: "smooth" });
    }
  }

}
