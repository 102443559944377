import {Component, inject, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {isDevMode} from '@angular/core';
import {Location} from "@angular/common";
import {ComponentBindingService} from "../../providers/services/component-binding.service";
import {InformMessagesConstant} from "../constants/inform-messages.constant";


@Component({
  selector: 'base-helper',
  template: ""
})
export class BaseHelperComponent implements OnDestroy {
  isLoading = false;
  informMessages = InformMessagesConstant;

  protected _subs: Subscription = new Subscription();
  protected location = inject(Location)
  protected componentBindingService = inject(ComponentBindingService);

  constructor() {
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  back() {
    this.location.back();
  }

  isDevelopment() {
    return isDevMode();
  }

}
