export const ValidationMessagesConstant = {
  Required: "This field is required",
  Email: "Էլ․ փոստի սխալ ձև",
  PhoneNumber: "Հեռախոսահամարը կարող է պարունակել 6-30 հատ թիվ կամ '+' սիմվոլը",
  Max255: "Թույլատրվում է մինչև 255 սիմվոլ",
  amLetters_space: 'Թույլատրվում է ավելացնել հայկական տառեր, բացատ ու գիծ(-)։',
  maxlength(length: number) {
    return `Առավելագույնը կարելի է ներմուծել  ${length} սիմվոլ։`
  },
}
