import {Component, OnInit} from '@angular/core';
import {BaseHelperComponent} from "../../../../core/helper-components/base.helper-component";
import {TechnologyClient, TechnologyFullResponse} from "../../../../web-api-client";
import {ElementRef, ViewChild} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent extends BaseHelperComponent implements OnInit {

  technologies: TechnologyFullResponse[] = [];
  @ViewChild('sectionElement') servicesElement!: ElementRef;
  @ViewChild('owlCarousel', {static: true}) owlCarousel!: any;

  carouselOptions: OwlOptions = {
    margin: 10,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navText: ['prev', 'next'],
    center: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      650: {
        items: 5
      },
      960: {
        items: 7
      }
    },
  };

  constructor(
    private _technologyClient: TechnologyClient) {
    super();
  }

  ngOnInit(): void {
    this.getTechnologies();
  }

  currentIndex = 0;
  itemWidth: number = 100;

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.technologies.length;
  }

  prev(): void {
    this.currentIndex = this.currentIndex === 0 ? this.technologies.length - 1 : this.currentIndex - 1;
  }

  private getTechnologies() {
    this._subs.add(
      this._technologyClient.getFull()
        .subscribe(_ => {
          this.technologies = _;
        })
    )
  }

}

export class Images {
  thumbImage?: string
  image?: string
  title?: string
}
