import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  @ViewChild('sectionElement') servicesElement!: ElementRef;

  constructor(
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    //this.setupIntersectionObserver()
    //this.reveal();

  } private wasInView = false;

  private setupIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.wasInView) {
            this.zone.run(() => {
              this.servicesElement.nativeElement.classList.add('active');
              this.wasInView = true;
            });
          }
        });
      },
      options
    );

    observer.observe(this.servicesElement.nativeElement);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.reveal(); // Call reveal on scroll
  }

  private reveal() {
    const reveals = document.querySelectorAll('.reveal');

    for (let i = 0; i < reveals.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = reveals[i].getBoundingClientRect().top;
      const elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }


}
