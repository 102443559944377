import {Component, Input, OnInit} from '@angular/core';
import {BaseHelperComponent} from "../../../core/helper-components/base.helper-component";

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent extends BaseHelperComponent implements OnInit {

  @Input() title = '';
  @Input() showDisabledButton = false;
  @Input() showAddButton = true;
  @Input() showButtons = true;
  @Input() showBackButton = true;
  @Input() route = 'create';

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
