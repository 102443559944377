<body>
<!--
  <app-home></app-home>
-->
  <div class="container"  style="min-height: 100vh; flex-direction:column;display:flex ">
    <router-outlet></router-outlet>
  </div>
</body>

<notifier-container></notifier-container>
