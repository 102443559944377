import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {FileService} from "../../providers/services/fileService";

@Component({
  selector: 'app-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.css']
})
export class ImageDisplayComponent implements OnInit {

  @Input() id!: string | undefined;
  @Input() customStyle!: string | undefined;
  imageData: SafeUrl | null = null;
  svgContent: SafeHtml | null = null;
  isSVG: boolean = false;

  constructor(private sanitizer: DomSanitizer,
              private fileService: FileService) {
  }

  ngOnInit(): void {
    if (this.id) {
      this.fileService.downloadFile(this.id).subscribe(
        (data: Blob) => {
          if (data.type === 'image/svg+xml') {
            this.isSVG = true;
            this.blobToText(data).then(svgText => {
              this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgText);
            });
          } else {
            const objectURL = URL.createObjectURL(data);
            this.imageData = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          }
        },
        (error: any) => {
          console.error('Error downloading image:', error);
        }
      );
    }
  }

// Method to convert Blob to text
  blobToText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  ngOnChanges(): void {

  }
}
