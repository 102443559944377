import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthorizeGuard} from "./providers/guards/authorize.guard";
import {UnauthorizeGuard} from "./providers/guards/unauthorize.guard";

export const routes: Routes = [
  {
    path: "identity",
    loadChildren: () =>
      import("./ui/modules/identity/identity.module").then(
        (m) => m.IdentityModule),
    canActivate: [UnauthorizeGuard]
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./ui/modules/admin/admin.module").then(
        (m) => m.AdminModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: "organizations",
    loadChildren: () =>
      import("./ui/modules/identity/identity.module").then(
        (m) => m.IdentityModule),
    canActivate: [AuthorizeGuard]
  },
/*  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthorizeGuard]
  },*/
/*  {
    path: '',
    loadChildren: () =>
      import("./ui/modules/home/home.module").then(
        (m) => m.HomeModule),
    pathMatch: 'full',
   // canActivate: [AuthorizeGuard]
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
