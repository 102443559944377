import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {HeaderComponent} from "./header/header.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {InformDialogComponent} from "./inform-dialog/inform-dialog.component";
import {MaterialModule} from "../../material.module";
import {HeadingComponent} from "./heading/heading.component";


@NgModule({
  declarations: [
    HeaderComponent,
    ConfirmDialogComponent,
    InformDialogComponent,
    HeadingComponent
  ],
  exports: [
    HeaderComponent,
    ConfirmDialogComponent,
    InformDialogComponent,
    HeadingComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,

  ],
  providers: []

})
export class WidgetsModule {
}
