<div class="footer">
  <div class="container">
    <div id="info" class="item-1">
      <span>  © Capital Soft 2024</span>
    </div>

    <div id="social-icons">
      <ul>
        <mat-divider vertical></mat-divider>
        <li>
          <a href="https://www.facebook.com/profile.php?id=100010542516857"
             target="_blank" class="icon-facebook">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
        <mat-divider vertical></mat-divider>
        <li>
          <a href="https://www.comcapitalsoft@gmail.com"
             target="_blank" class="icon-google_plus">
            <i class="fab fa-google-plus-g"></i>
          </a>
        </li>
        <mat-divider vertical></mat-divider>
        <li>
          <a href="https://www.skype.com"
             target="_blank" class="icon-tumblr">
            <i class="fab fa-skype"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!--<div class="container">
  <div class="item item1">Item 1</div>
  <div class="item item2">Item 2</div>
</div>-->
