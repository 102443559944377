import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, switchMap, take, throwError, mergeMap} from "rxjs";
import {
  catchError, filter,
} from "rxjs/operators";
import {IdentityService} from "../services/identity.service";
import {ComponentBindingService} from "../services/component-binding.service";
import {IdentityClient} from "../../web-api-client";


@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private identityService: IdentityService, private identityClient: IdentityClient, private componentBindingService: ComponentBindingService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(this.addTokenToHeader(request, this.identityService.accessToken)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.url?.toString().includes("RenewTokens")) {
          this.identityService.removeAccessToken();
          location.reload();
        }
        if (error?.status === 401) {
          return this.handle401Error(request, next);
        }
        if (error.status == 403) {
          this.componentBindingService.open403Popup$.next(true);
        }
        if (error.status == 400 || error.status == 404) {
          return this.blobToText(error.error).pipe(mergeMap((_responseText: string) => {
            const result = _responseText === "" ? null : JSON.parse(_responseText);
            return throwError(() => result);
          }));
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessToken$.next(null);

      return this.identityClient.renewTokens().pipe(
        switchMap((response: any) => {
          this.accessToken$.next(response?.accessToken);
          this.identityService.setAccessToken(
            response?.accessToken
          );
          this.isRefreshing = false;

          return next.handle(
            this.addTokenToHeader(request, response?.accessToken)
          );
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshing = false;
          return throwError(() => error);
        })
      );
    }
    return this.accessToken$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenToHeader(request, token)))
    ) || throwError(() => "on return exception");
  }


  private addTokenToHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set("Authorization", "Bearer " + token),
    });
  }

  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next("");
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          observer.next((event.target as any).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
}

