import {AfterContentInit, AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { BaseHelperComponent } from "../../../../core/helper-components/base.helper-component";
import { ProjectClient, ProjectFullResponse } from "../../../../web-api-client";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Images } from '../technologies/technologies.component';
import { FileService } from '../../../../providers/services/fileService';

@Component({
  selector: 'app-work-details',
  templateUrl: './work-details.component.html',
  styleUrls: ['./work-details.component.scss']
})
export class WorkDetailsComponent extends BaseHelperComponent implements OnInit {

  id!: number;
  project!: ProjectFullResponse;
  @ViewChild('container') container!: ElementRef;

  constructor(
    private projectClient: ProjectClient,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProject();
  }


  description(html: string | undefined) {
    if (!html)
      return "";
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  imageObject: Images[] = []
  private getProject() {
    this._subs.add(
      this.projectClient.getProjectDetails(this.data.id).subscribe(_ => {
        this.project = _;
        this.project?.fileIds?.forEach(id => {
          this.fileService.downloadFile(id).subscribe(
            (imageData: Blob) => {
              const objectURL = URL.createObjectURL(imageData);
              this.imageObject.push({
                thumbImage: objectURL,
                image: objectURL,
              });
            },
          );
        });
      })
    );
  }

}
