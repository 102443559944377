import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseHelperComponent} from "./core/helper-components/base.helper-component";
import {UpsertHelperComponent} from "./core/helper-components/upsert.helper-component";
import {WidgetsModule} from "./ui/widgets/widgets.module";
import {AppInitializeService} from "./providers/services/app-initialize.service";
import {AppInterceptor} from "./providers/interceptors/app.interceptor";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorIntlService} from "./providers/services/mat-paginatorIntl.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ComponentBindingService} from "./providers/services/component-binding.service";
import {IdentityService} from "./providers/services/identity.service";
import { NotifierModule } from 'angular-notifier';
import { NOTIFIER_OPTIONS } from './core/constants/notifer.options';
import {HomeModule} from "./ui/modules/home/home.module";
import { NgImageSliderModule } from 'ng-image-slider';

export function appInitialize(appInitializeService: AppInitializeService) {
  return (): Promise<any> => {
    return appInitializeService.Init();
  }
}

@NgModule({
  declarations: [
    BaseHelperComponent,
    UpsertHelperComponent,
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WidgetsModule,
    NotifierModule.withConfig(NOTIFIER_OPTIONS),
    HomeModule,
    NgImageSliderModule
  ],
  providers: [
    AppInitializeService,
    ComponentBindingService,
    IdentityService,
    MatPaginatorIntlService,
    {provide: APP_INITIALIZER, useFactory: appInitialize, deps: [AppInitializeService], multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlService
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
