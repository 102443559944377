import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basebee',
  templateUrl: './basebee.component.html',
  styleUrls: ['./basebee.component.css']
})
export class BasebeeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  navigateToBot() {
    window.open("https://basebee.bot", "_blank");
  }

}
