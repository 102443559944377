import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    trigger('fadeInUp', [
      state('inactive', style({
        opacity: 0,
        transform: 'translateY(100%)'
      })),
      state('active', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('inactive => active', animate('500ms ease-in')),
    ]),
  ],
})
export class AboutComponent implements OnInit {

  @ViewChild('titleContainer') titleContainer!: ElementRef;
  state = 'inactive';

  constructor(
    private renderer: Renderer2
  ) { }
  ngOnInit(): void {
    //window.addEventListener('scroll', this.checkScroll.bind(this));

    //this.renderer.listen('window', 'scroll', () => {
    //  this.checkScroll();
    //});
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    //const titleContainerOffset = this.titleContainer.nativeElement.offsetTop;
    //const scrollPosition = window.scrollY + window.innerHeight;

    //if (scrollPosition > titleContainerOffset) {
    //  this.state = 'active';
    //}
  }
}
//,
//animations: [
//  trigger('fadeIn', [
//    state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
//    state('*', style({ opacity: 1, transform: 'translateY(0)' })),
//    transition('void => *', animate('0.5s ease-in')),
//  ])
//]
