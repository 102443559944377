import {Component, inject} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {BaseHelperComponent} from "./base.helper-component";
import {ValidationMessagesConstant} from "../constants/validation-messages.constant";
import {ValidationPatternsConstant} from "../constants/validation-patterns.constant";

@Component({
  selector: 'upsert-helper',
  template: ""
})
export class UpsertHelperComponent extends BaseHelperComponent {
  form!: FormGroup;
  validationMessages = ValidationMessagesConstant;
  validationPatterns = ValidationPatternsConstant;

  constructor() {
    super()
  }


}
